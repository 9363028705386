.oeffnungszeiten {
  @apply bg-[length:30px] lg:bg-[length:40px];
  background-image: url('../../../public/images/nav-item-oeffnungszeiten-negativ.svg');
}

.shop {
  @apply bg-[length:38px] lg:bg-[length:50px];
  background-image: url('../../../public/images/nav-item-shop-negativ.svg');
}

.mytamina {
  @apply bg-[length:30px] lg:bg-[length:40px];
  background-image: url('../../../public/images/nav-item-mytamina-negativ.svg');
}
