  .nav-item-thermalbad-negativ {
    background-image: url('../../public/images/nav-item-thermalbad-negativ.svg');
  }

  .nav-item-saunawelt-negativ {
    background-image: url('../../public/images/nav-item-saunawelt-negativ.svg');
  }

  .nav-item-kulinarik-negativ {
    background-image: url('../../public/images/nav-item-kulinarik-negativ.svg');
  }

  .nav-item-wellness-negativ {
    background-image: url('../../public/images/nav-item-wellness-negativ.svg');
  }

  .nav-item-gutschein-negativ {
    background-image: url('../../public/images/nav-item-gutschein-negativ.svg');
  }

  .nav-item-warenkorb-negativ {
    background-image: url('../../public/images/nav-item-warenkorb-negativ.svg');
  }

  .nav-item-oeffnungszeiten-negativ {
    background-image: url('../../public/images/nav-item-oeffnungszeiten-negativ.svg');
  }

  .nav-item-events-negativ {
    background-image: url('../../public/images/nav-item-events-negativ.svg');
  }

  .nav-item-mytamina-negativ {
    background-image: url('../../public/images/nav-item-mytamina-negativ.svg');
  }
