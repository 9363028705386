.imageContainer {
  width: 100%;
  @apply mb-4 lg:mb-0;

  >div {
      position: unset !important;
  }

  .image {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
  }
}
