.imageContainer {
  width: 100%;
  @apply max-h-[430px] mt-auto lg:pl-40 relative;

  >div {
      position: unset !important;
  }

  .image {
      object-fit: contain;
      position: relative !important;
      width: unset !important;
      height: 100% !important;
      max-height: 430px;
  }
}
